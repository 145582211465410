// Check.js
import React, { useState, useEffect } from 'react';



const Accept = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await fetch('https://backend-nine-vert.vercel.app/register-info');
      const data = await response.json();
     
      setAttendanceData(data.data);
    } catch (error) {
      console.error('Error fetching attendance data:', error);
      // Handle the error appropriately
    } finally {
      setLoading(false);
    }
  };



  return (
    
   
    <div className='container mx-auto p-4 h-screen overflow-x-auto'>
    
      <h2 className='text-2xl font-semibold mb-4'>Booking Data</h2>
   

      {loading ? (
        <div className="border-t-8 border-blue-500 border-solid rounded-full h-16 w-16 border-t-blue-600 animate-spin mx-auto"></div>
      ) : (
        <table className="table-auto w-full bg-white rounded-lg overflow-hidden">
          <thead className="bg-gray-800 text-white">
            <tr>
            <th className="border px-4 py-2">Booking Date</th>
              <th className="border px-4 py-2">Name</th>
              <th className="border px-4 py-2">email</th>
              <th className="border px-4 py-2">mobile</th>
              <th className="border px-4 py-2">Taxi Type</th>
              <th className="border px-4 py-2">Total Passangers</th>
              <th className="border px-4 py-2">Pickup point</th>
              <th className="border px-4 py-2">Drop point</th>
              <th className="border px-4 py-2">Pickup date</th>
              <th className="border px-4 py-2">Pickup time</th>
              <th className="border px-4 py-2">Total bags</th>
              
            </tr>
          </thead>
          <tbody>
            {attendanceData
            
                .map(item => {

                  return(
                    <>
               
                    {
                      item.book
                      .sort((a, b) => b._id.localeCompare(a._id))
                      
                      .map((b) => {
                        return(
                          <tr
                          
                          className="bg-gray-100 hover:bg-yellow-200 transition duration-300"
                        >
                        <td className="border px-4 py-2">{new Date(b.currentDate).toLocaleDateString('en-GB')}</td>
                          <td className="border px-4 py-2">{b.name}</td>
                          <td className="border px-4 py-2">{b.email}</td>
                          <td className="border px-4 py-2">{b.mobile}</td>
                          <td className="border px-4 py-2">{b.selecttaxi}</td>
                          <td className="border px-4 py-2">{b.numofpass}</td>
                          <td className="border px-4 py-2">{b.pickuppoint}</td>
                          <td className="border px-4 py-2">{b.droppoint}</td>
                          <td className="border px-4 py-2">{new Date(b.pickupdate).toLocaleDateString('en-GB')}</td>
                          <td className="border px-4 py-2">{b.pickuptime}</td>
                          <td className="border px-4 py-2">{b.numofbags}</td>
                        </tr>
                        )
                      })
                    }
                    
                    </>
                  )
                }
              
            
          
          
          )}
          </tbody>
        </table>
      )}
    </div>
   
  )
};

export default Accept;
