import React, { useState, useEffect, useContext } from 'react';
import MyContext from './MyContext';

const Students = () => {
  const [vData, setVdata] = useState([]);

  const {loadings,setLoadings} = useContext(MyContext)
const fetchData = async () => {
    setLoadings(true);

    try {
      const response = await fetch('https://backend-nine-vert.vercel.app/vehicle-info');
      const data = await response.json();
      setVdata(data.data);
    } catch (error) {
      console.error('Error fetching attendance data:', error);
    } finally {
      setLoadings(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  



  return (
    <div className='container mx-auto p-4 h-screen overflow-x-auto'>
      <h2 className='text-2xl font-semibold mb-4'>Vehicle Data</h2>
      {loadings ? (
        <div className="border-t-8 border-blue-500 border-solid rounded-full h-16 w-16 border-t-blue-600 animate-spin mx-auto"></div>
      ) : (
        <table className="table-auto w-full bg-white rounded-lg overflow-hidden">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="border px-4 py-2">Name</th>
              <th className="border px-4 py-2">Email</th>
              <th className="border px-4 py-2">Mobile</th>
              <th className="border px-4 py-2">carname</th>
              <th className="border px-4 py-2">carmodel</th>
              <th className="border px-4 py-2">licensenumber</th>
              <th className="border px-4 py-2">vehicleyear</th>
              <th className="border px-4 py-2">vehiclecolor</th>
              <th className="border px-4 py-2">Location</th>
             
            
            
            </tr>
          </thead>
          <tbody>
            {vData
              .sort((a, b) => b._id.localeCompare(a._id))
              .map(item => (
              <tr key={item._id} className="bg-gray-100 hover:bg-yellow-200 transition duration-300">
                <td className="border px-4 py-2">{item.name}</td>
                <td className="border px-4 py-2">{item.email}</td>
                <td className="border px-4 py-2">{item.mobile}</td>
                <td className="border px-4 py-2">{item.carname}</td>
                <td className="border px-4 py-2">{item.carmodel}</td>
                <td className="border px-4 py-2">{item.licensenumber}</td>
                <td className="border px-4 py-2">{item.vehicleyear}</td>
                <td className="border px-4 py-2">{item.vehiclecolor}</td>
                <td className="border px-4 py-2">{item.city}</td>
              
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Students;
