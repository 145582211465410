// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Sidebar from './Pages/Sidebar';
import Enquiry from './Pages/Enquiry';
import Attendance from './Pages/Attendance';
import WorkDone from './Pages/WorkDone';
import Home from './Pages/Home';
import MyContextProvider from './Pages/MyContextProvider';
import Students from './Pages/Students';

const App = () => {
  const initialLoginState = localStorage.getItem('login') === 'true';
  const [login, setLogin] = useState(initialLoginState);

  // Save login state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('login', login.toString());
  }, [login]);
  return (
    <BrowserRouter>
    <MyContextProvider>
    {
      !login ?

      <Home setLogin={setLogin}/>:

      <div className="flex">
      <Sidebar />
      <Routes>
      <Route path="/" element={<Home setLogin={setLogin}/>} />
        <Route path="/enquiry" element={<Enquiry />} />
        <Route path="/register" element={<Attendance />} />
        <Route path="/book-done" element={<WorkDone />} />
        <Route path="/vehicle-data" element={<Students />} />
      
        
      
      </Routes>
      
    </div>

    }
    </MyContextProvider>
    </BrowserRouter>
  );
}

export default App;
