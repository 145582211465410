import React, { useEffect, useState } from 'react'
import MyContext from './MyContext'


const MyContextProvider = ({children}) => {
    const [enquiryData, setEnquiryData] = useState([]);
    const [passportData, setPassportData] = useState([]);
    const [loading, setLoading] = useState(true);

  
    useEffect(() => {
        fetchData();
        passdata()
      }, []);
    
      const fetchData = async () => {
        setLoading(true);
    
        try {
          const response = await fetch('https://backend-nine-vert.vercel.app/contact-info');
          
          const data = await response.json();
   

    
        
    
    
         
    
          setEnquiryData(data.data);
        } catch (error) {
          console.error('Error fetching enquiry data:', error);
        
        } finally {
          setLoading(false);
        }
      };


      
    
      const passdata = async () => {
        setLoading(true);
    
        try {
          const response = await fetch('https://bottom-seven.vercel.app/passport-info');
          const data = await response.json();
    
          // Sort data in descending order based on _id (assuming _id is a string)
          const sortedData = data.sort((a, b) => b._id.localeCompare(a._id));
    
        
    
    
         
    
          setPassportData(sortedData);
        } catch (error) {
          console.error('Error fetching enquiry data:', error);
          // Handle the error appropriately
        } finally {
          setLoading(false);
        }
      };
const[loadings,setLoadings] = useState(false)


const handleDelete = async (email) => {
  setLoadings(true);
  try {
    const response = await fetch('https://backend-nine-vert.vercel.app/remove-from-register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email }),
    });

    const data = await response.json();
    if (data.success) {
      alert(data.message);
      window.location.reload();
    } else {
      alert(data.error);
    }
    setLoadings(false);
  } catch (error) {
    console.error('Error removing from cart:', error);
  }
};


return (
<MyContext.Provider value={{loadings,setLoadings,handleDelete,enquiryData,loading,passportData}} >
{children}
</MyContext.Provider>
)
}

export default MyContextProvider