// Sidebar.js
import React from 'react';
import { BiBody } from "react-icons/bi";
import { BiCalendar } from "react-icons/bi";
import { Link, useLocation } from 'react-router-dom';
import { IoMdDocument, IoMdGitNetwork } from "react-icons/io";



const Sidebar = () => {



  const location = useLocation()

  if (location.pathname === '/'){
    return null
  }
   
  return (
    <div className="h-screen bg-gray-800 text-white w-1/4">
      <div className="p-4">
        <h1 className="text-2xl font-bold">Smart Cab Point</h1>
      </div>
      <nav className="mt-4">
        <ul>
          <li className="mb-2">
            <Link to="/enquiry" className="flex items-center p-2 bg-gray-700 hover:bg-gray-600 gap-2 relative" >
              Enquiry
              <span className="mr-2">
              
                <BiBody size={25} />
               
              </span>

            
             
            </Link>
          </li>
          <li className="mb-2">
          <Link to="/register" className="flex items-center p-2 bg-gray-700 hover:bg-gray-600 gap-2">
          Register
            <span className="mr-2">
              <BiCalendar size={25} />
            </span>
          </Link>
        </li>
        <li className="mb-2">
        <Link to="/book-done" className="flex items-center p-2 bg-gray-700 hover:bg-gray-600 gap-2">
          Bookings
          <span className="mr-2">
            <IoMdGitNetwork size={25} />
          </span>
        </Link>
      </li>
      <li className="mb-2">
      <Link to="/vehicle-data" className="flex items-center p-2 bg-gray-700 hover:bg-gray-600 gap-2">
        vehicle Registration
        <span className="mr-2">
          <BiBody size={25} />
        </span>
      </Link>
    </li>

   
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
