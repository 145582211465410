// Enquiry.js
import React, {useContext } from 'react';
import MyContext from './MyContext';

const Enquiry = () => {
  const{enquiryData,loading} = useContext(MyContext)

  return (
    <div className='container mx-auto p-4 h-screen overflow-x-auto'>
      <h2 className='text-2xl font-semibold mb-4'>Enquiry Data</h2>

      {loading ? (
        <div className="border-t-8 border-blue-500 border-solid rounded-full h-16 w-16 border-t-blue-600 animate-spin mx-auto"></div>
      ) : (
        <table className="table-auto w-full bg-white rounded-lg overflow-hidden">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="border px-4 py-2">Name</th>
              <th className="border px-4 py-2">Email</th>
              <th className="border px-4 py-2">Subject</th>
              <th className="border px-4 py-2">Message</th>
            </tr>
          </thead>
          <tbody>
            {enquiryData
            .sort((a, b) => b._id.localeCompare(a._id))
            .map(item => (
              <tr
                key={item._id}
                className="bg-gray-100 hover:bg-yellow-200 transition duration-300"
              >
                <td className="border px-4 py-2">{item.yourname}</td>
                <td className="border px-4 py-2">{item.youremail}</td>
                <td className="border px-4 py-2">{item.yoursub}</td>
                <td className="border px-4 py-2">{item.yourmessage}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Enquiry;
